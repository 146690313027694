import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ForceGraph } from "./force-graph";
import '../../css/device-graph.css';

export const DeviceGraph = ({ data, isSimpleView, setSimpleView }) => {
    const [ zoomScale, setZoomScale ] = useState(8);

    return (
        <div>
            <ForceGraph
                linksData={!isSimpleView ? data.DeviceLinks : data.ConflictDeviceLinks}
                nodesData={!isSimpleView ? data.Devices : data.ConflictDevices}
                zoomScale={zoomScale}
            >
                <ButtonGroup className="ZoomButtonGroup">
                    <Button
                        className="ZoomButton"
                        onClick={() => setZoomScale(8)}
                    >
                        Reset Zoom
                    </Button>
                    <Button
                        className="ZoomButton"
                        onClick={() => setZoomScale(zoomScale => zoomScale + 1)}
                    >
                        -
                    </Button>
                    <Button
                        className="ZoomButton"
                        onClick={() => setZoomScale(zoomScale => zoomScale - 1)}
                    >
                        +
                    </Button>
                </ButtonGroup>
                {(data.Devices.length !== data.ConflictDevices.length && data.ConflictDevices.length > 0) &&
                    <div> 
                        <ButtonGroup className="ViewButtonGroup">
                            <Button
                                className="SetViewButton"
                                onClick={() => setSimpleView(false)}
                                disabled={!isSimpleView}
                            >
                                Normal Graph
                            </Button>
                            <Button
                                className="SetViewButton"
                                onClick={() => setSimpleView(true)}
                                disabled={isSimpleView}
                            >
                                Simple Graph
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="CopyConflictDevicesButtonGroup">
                            <Button
                                className="CopyConflictDevicesButton"
                                onClick={() => navigator.clipboard.writeText(data.ConflictDevicesList.join(", "))}
                            >
                                Copy Conflict Device{data.ConflictDevicesList.length === 1 ? "" : "s"}
                            </Button>
                        </ButtonGroup>

                    </div>
                }
            </ForceGraph>
        </div>
    )
}
