import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import Constants from '../components/Constants';
import { Application } from "../config/authConfig";

const AdminContext = createContext();

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
    const { instance, accounts } = useMsal();
    const [isAdmin, setIsAdmin] = useState(false);
    const userName = accounts[0]?.username;
    const [token, setToken] = useState();
    const request = {
        ...Application.clientApi,
        account: accounts[0]
    };

    useEffect(() => {
        instance.acquireTokenSilent(request).then((response) => {
            setToken(response.accessToken);
        }).catch((e) => {
            console.log(e, "exception");
        });
    }, [instance, request]);

    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                try {
                    const url = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/GetUserRole?userName=' + userName;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        }
                    });
                    const resultJson = await response.json();
                    if (resultJson["data"] === Constants.Admin) {
                        setIsAdmin(true);
                        return;
                    }
                } catch (error) {
                    console.log(error, "exception");
                }
            };
            fetchData();
        }
    }, [token, userName]);

    return (
        <AdminContext.Provider value={{ isAdmin }}>
            {children}
        </AdminContext.Provider>
    );
};