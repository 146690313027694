import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Constants from '../Constants';

export const RevalidateChange = ({ change, revalidateChange, buttonLoading, closeDisabled}) => {
    if ([Constants.InReviewStatus, Constants.RejectedStatus].includes(change.status) && !closeDisabled) {
        return (
            <Button
                variant="outline-success"
                onClick={() => {
                    revalidateChange(change);
                }}
                disabled={buttonLoading}
            >
                {buttonLoading ? 'Revalidating...' : 'Revalidate'}
            </Button>
        );
    }
    else
    {
        return null;
    }
}