import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/App.css';
import '../../css/change-record.css';
import { Alert, Col, Container, Button, Dropdown, Form, Table, Row, InputGroup, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react"
import { Application } from "../../config/authConfig";
import { Helmet } from 'react-helmet-async';
import Constants from '../Constants';
import { ViewChangeRecord } from "./ViewChangeRecord";
import { AddChangeRecord } from "./AddChangeRecord";
import { DateRangePicker } from "./DateRangePicker";
import { SearchForm } from "./SearchForm";
import { ChangeRecordTable } from './ChangeRecordTable';
import dayjs from 'dayjs';

export const ChangeRecordHomePage = () => {
    const { changeId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null)
    const [token, setToken] = useState();
    const { instance, accounts } = useMsal();
    const [viewModal, setShowViewModal] = useState(false);
    const [addModal, setShowAddModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [user, setUser] = useState(accounts[0]?.name + ' <' + accounts[0]?.username + '>');
    const [isFromLink, setIsFromLink] = useState(true);
    const [navigateError, setNavigateError] = useState(null);
    const [showNavigateError, setShowNavigateError] = useState(false);
    const [loading, setLoading] = useState(false);

    // Search form
    const [startWindow, setStartWindow] = useState(dayjs().format('YYYY-MM-DD'));
    const [endWindow, setEndWindow] = useState(dayjs().add(7, 'days').format('YYYY-MM-DD'));
    const [filterInput, setFilterInput] = useState("");
    const [filterType, setSearchFilterType] = useState('');
    const [searchText, setSearchFilterText] = useState('');
    const [filterFromView, setFilterFromView] = useState(false);
    const [originalData, setOriginalData] = useState([]);

    const request = {
        ...Application.clientApi,
        account: accounts[0]
    }

    instance.acquireTokenSilent(request).then((response) => {
        setToken(response.accessToken);
    }).catch((e) => {
        console.log(e, "exception");
    })

    const bearer = 'Bearer ' + token;

    const options = {
        method: 'GET',
        headers: {
            'Authorization': bearer,
        }
    };

    async function handleTimeWindowChange(window) {
        setStartWindow(window.startWindow);
        setEndWindow(window.endWindow);
        setRefreshTable(!refreshTable);
    }

    const getChangeRecords = (api) => {
        fetch(api, options)
            .then(res => res.json())
            .then((result) => {
                if (result[Constants.responseCode] === Constants.SuccessAPIResponseCode) {
                    if (!Array.isArray(result["data"])) {
                        setData([result["data"]]);
                        setOriginalData([result["data"]]);
                    }
                    else {
                        setData(result["data"]);
                        setOriginalData(result["data"]);
                    }
                    setError(null);
                }
                else {
                    setError(result["ResponseMessage"]);
                    setData([]);
                    setOriginalData([]);
                }
            },
                (error) => {
                    setError("There was an error processing your request");
                })
            .catch((e) => console.log(e, "apiErro"))
            .finally(() => {
                setButtonLoading(false);
                setLoading(false);
            });
    }

    const handleClearFilter = () => {
        setData(originalData);
        setFilterFromView(false);
        document.getElementById("searchForm").reset();
    }

    /**
    * Fetches the change record data when user navigates to the page from a link.
    * Success: shows the edit modal with the fetched data.
    * Failure: shows alert and navigates back to Change Record page.
    */
    useEffect(() => {
        if (isFromLink && changeId && token) {
            const api = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetChangeById/?changeId=' + changeId;
            fetch(api, options)
                .then(res => res.json())
                .then((result) => {
                    if (result[Constants.responseCode] === Constants.SuccessAPIResponseCode) {
                        handleShowEditModal(result["data"]);
                    }
                    else if (result[Constants.responseCode] === Constants.NoDataFoundAPIResponseCode) {
                        setNavigateError("Could not find the change record " + changeId);
                        handleNavigateError()
                    }
                })
                .catch((e) => {
                    console.log(e, "apiError")
                    setNavigateError("There was an error processing your request to view change record " + changeId);
                    handleNavigateError()
                });
        }
    }, [changeId, token]);

    /**
     * Closes the edit modal and removes changeId from the url.
     */
    const handleCloseEditModal = () => {
        setShowViewModal(false);
        navigate('/changeRecord');
    }

    /**
     * Shows the edit modal adds changeId to the url.
    */
    const handleShowEditModal = (item) => {
        setIsFromLink(false);
        setEditData(item);
        setShowViewModal(true);
        navigate(`/changeRecord/${item.changeId}`);
    }

    /**
     * Shows the alert and navigates back to the change record page. 
    */
    const handleNavigateError = () => {
        navigate('/changeRecord');
        setShowNavigateError(true);
    }

    const handleSearchSubmit = (event) => {

        setButtonLoading(true);
        event.preventDefault();
        if (searchText) {
            var api = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetChanges/?filterType=' + filterType + '&filterString=' + (searchText === '' ? "empty" : searchText);
        }
        if (filterFromView) {
            const key = Constants.FilterMapping[filterType];
            const filteredData = originalData.filter(item => {
                
                if (Array.isArray(item[key])) {
                    return item[key].some(device => device.includes(searchText));
                } else {
                    return item[key].includes(searchText);
                }
            });
            setData(filteredData);
            setButtonLoading(false);
        } else {
            getChangeRecords(api);
        }
    }

    const setEditAndViewModal = (editDataValue, viewModalValue) => {
        setEditData(editDataValue);
        setShowViewModal(viewModalValue);
        navigate(`/changeRecord/${editDataValue.changeId}`);
    };

    useEffect(() => {
        setLoading(true);
        if (token != null) {
            getChangeRecords(process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetChangesScheduled/?startWindow=' + startWindow + '&endWindow=' + endWindow + '&onlyApproved=false')
        }
    }, [token, addModal, refreshTable]);

    /**
     * Sets the filter type and filter input.
     */
    const handleFilterInputChange = (event) => {
        const value = event.target.value;
        setSearchFilterType(value);
        setFilterInput(value);
    };

    return (
       <div className="home-container">
            <Helmet>
                <title>PhyNet Change Management Home {process.env.REACT_APP_API_CLIENT_URL}</title>
            </Helmet>
            <div>
                <Row className='header-row'>
                    <Col sm={8}>
                        <h3>Phynet Change Management</h3>
                    </Col>
                    <Col sm={4} className='add-change-btn-col'>
                        <Button name="addButton" className="InputButton add-change-btn"
                            onClick={() => {
                                setShowAddModal(true);
                            }}>
                            <b>+ </b>New Change
                        </Button>
                    </Col>

                </Row>
                <Row>
                    <Col sm ={8}>
                        <SearchForm
                            filterType={filterType}
                            searchText={searchText}
                            buttonLoading={buttonLoading}
                            handleFilterInputChange={handleFilterInputChange}
                            setSearchFilterText={setSearchFilterText}
                            handleSearchSubmit={handleSearchSubmit}
                            setFilterFromView={setFilterFromView}
                            filterFromView={filterFromView}
                            handleClearFilter={handleClearFilter}
                        />
                    </Col>
                </Row>
                <Row>
                    <DateRangePicker
                        startWindow={startWindow}
                        endWindow={endWindow}
                        onDateRangeChange={(window) =>
                            handleTimeWindowChange(window)
                        }
                        refreshTable={refreshTable}
                        setRefreshTable={setRefreshTable}>
                    </DateRangePicker>
                </Row>
                {addModal && <AddChangeRecord
                    show={addModal}
                    bearer={bearer}
                    user={user}
                    onhide={() => setShowAddModal(false)}
                    setEditAndViewModal={setEditAndViewModal}
                />}
            </div>
            <div>
                <ChangeRecordTable
                    data={data}
                    loading={loading}
                    handleShowEditModal={handleShowEditModal}
                />
                {editData && viewModal && (
                    <ViewChangeRecord
                        show={handleShowEditModal}
                        item={editData}
                        bearer={bearer}
                        user={user}
                        onhide={handleCloseEditModal}
                    />)
                }
                {error ? (
                    <Alert className="navigate-error-alert" variant="danger"
                        onClose={() => {
                            setShowNavigateError(false);
                            setError('')
                        }} dismissible>
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                ) : (navigateError && showNavigateError) ? (
                    <Alert className="navigate-error-alert" variant="danger" onClose={() => setShowNavigateError(false)} dismissible>
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{navigateError}</p>
                    </Alert>
                ) : null}
            </div>
        </div>
    );
};