import Constants from "../Constants";
import React, { useEffect, useState } from "react";
import WindowedSelect from "react-windowed-select";
import '../../css/change-record.css';

export const MaintenanceTypeDropdown = ({
    handleInputChange,
    maintenanceType,
    setMaintenanceType,
    isMopRequired,
    setIsMopRequired,
}) => {
    const options = Constants.MopReqMaintenanceTypes.concat(
        Constants.NoMopReqMaintenanceTypes
    ).map((option) => ({ value: option, label: option }));
    const [description, setDescription] = useState(null);

    useEffect(() => {
        if (Constants.MopReqMaintenanceTypes.includes(maintenanceType)) {
            setIsMopRequired(true);
        } else if (Constants.NoMopReqMaintenanceTypes.includes(maintenanceType)) {
            setIsMopRequired(false);
        }
    }, [maintenanceType, setIsMopRequired]);

    const handleChange = (selectedOption) => {
        setMaintenanceType(selectedOption.value);
        handleInputChange({
            target: { name: "maintenanceType", value: selectedOption.value },
        });
        handleInputChange({
            target: { name: "devices", value: [] },
        
        })
        setDescription(Constants.MaintenanceTypeDescriptions[selectedOption.value] || null);
    };

    return (
        <div className="mb-3">
            <label>
                Maintenance Type<span className="text-danger">*</span>
            </label>
            <WindowedSelect
                required
                name="maintenanceType"
                onChange={handleChange}
                value={options.find((option) => option.value === maintenanceType)}
                options={options}
            />
        {description && <p className="mt-2 description-text">{description}</p>}
        </div>
    );
};
