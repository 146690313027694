import { Button, Form, Spinner, Modal, Row, Col } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Application } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";

export const AddBlockListItem = ({ show, onhide }) => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState();
    const [dropdownData, setDropdownData] = useState(null);
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        "deviceRegex": "", "cluster": "", "datacenter": "", "region": "", "deploymentType": "", "ngsDeviceType": "", "hardwareSku": "", "allowDcOpsMaintenance": "", "startTime": "", "endTime": ""
    });
    const [error, setError] = useState(null);
    const [matchedData, setMatchedData] = useState({ 'cluster': [] });
    const [selectedOptions, setSelectedOptions] = useState({ 'cluster': '', 'datacenter': '', 'region': '', 'deploymentType': '', 'ngsDeviceType': '' });
    const [submitResponse, setSubmitResponse] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [closeDisabled, setCloseDisabled] = useState(false);


    /* gets token for api calls */
    useEffect(() => {
        const request = {
            ...Application.clientApi,
            account: accounts[0]
        };

        instance
            .acquireTokenSilent(request)
            .then((response) => {
                setToken(response.accessToken);
            })
            .catch((e) => {
                console.log(e, "exception");
            });
    }, [instance, accounts]);

    /* Gets data for dropdown*/
    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                try {
                    const bearer = "Bearer " + token;
                    const options = {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': bearer,
                        },
                    };
                    const url = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/DropdownData';
                    const response = await fetch(url, options);
                    const data = await response.json();
                    setDropdownData(data.data);
                    setLoading(false);
                } catch (error) {
                    console.log(error, "exception");
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [token]);

    const handleInputChange = (e) => {
        setCloseDisabled(true);
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    /*Handles searching for dropdown */
    const handleSelectInputChange = debounce((inputValue, action) => {
        setSearching(true);
        const { name } = action;
        if (inputValue && inputValue.length >= 2) {
            const filteredList = dropdownData[name]?.filter(data =>
                data.toLowerCase().includes(inputValue.toLowerCase())
            ).sort();
            setMatchedData(prev => ({ ...prev, [name]: filteredList }));
        } else {
            setMatchedData(prev => ({ ...prev, [name]: [] }));
        }
        setSearching(false);
    }, 300);

    /* handles input change for dropdown */
    const handleSelectChange = (selectedOption, action) => {
        setCloseDisabled(true);
        const { name } = action;
        setSelectedOptions(prev => ({ ...prev, [name]: selectedOption ? selectedOption.value : "" }));
        setData(prev => ({ ...prev, [name]: selectedOption ? selectedOption.value : "" }));
    };

    const handleUpdate = (event) => {
        event.preventDefault();

        const bearer = "Bearer " + token;
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearer,
            },
            body: JSON.stringify(data)
        };

        const url = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/AddBlocklist'
        if (!closeDisabled) {
            return;
        }

        const startTime = new Date(data.startTime);
        const endTime = new Date(data.endTime);

        // Check if the end time is before the start time
        if (endTime < startTime) {
            setError("End time must be after start time");
            setShowModal(true);
            return;
        }

        const now = new Date();
        const nowInUTC = new Date(
            now.getUTCFullYear(), now.getUTCMonth(),
            now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()
        );

        if (endTime < nowInUTC) {
            console.log(startTime, now, "start time")
            setError("Cannot add blocklist. End time: " + endTime.toUTCString()+" has already passed");
            setShowModal(true);
            return;
        }

        fetch(url, options)
            .then(res => res.json())
            .then((result) => {
                setError(null);
                setSubmitResponse(result);
                setCloseDisabled(false);
                setShowModal(true);
            },
                (error) => {
                    setError(error);
                    setShowModal(true);

                })
            .catch((e) => {
                console.log(e, "apiError");
                setError("Internal Service Error")
                setShowModal(true);
            }
            );
    };

    const closeModalDone = () => {
        if (error) {
            closeModal();
        }
        else {
            onhide();
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const handleClose = () => {
        if (closeDisabled) {
            setShowExitModal(true);
        }
        else {
            onhide();
        }
    };

    return (
        <>
            <Modal className="cr-modal" size="lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Entry in BlockList</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <Spinner animation="border" role="status" variant="primary">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Form onSubmit={handleUpdate}>
                            <Form.Group className="mb-3" controlId="deviceRegex">
                                <Form.Label>Device List regex</Form.Label>
                                <Form.Control type="text" name="deviceRegex" placeholder="Regular expression" onChange={handleInputChange} />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="clusterName">
                                        <Form.Label>Cluster Name</Form.Label>
                                        <WindowedSelect
                                            placeholder="Search..."
                                            name="cluster"
                                            value={selectedOptions.cluster ? { label: selectedOptions.cluster, value: selectedOptions.cluster } : null}
                                            onChange={handleSelectChange}
                                            onInputChange={(inputValue) => handleSelectInputChange(inputValue, { name: "cluster" })}
                                            options={matchedData.cluster.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            windowThreshold={10}
                                            noOptionsMessage={({ inputValue }) =>
                                                inputValue?.length === 0 ? "" : "No Results Found"
                                            } />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="datacenter">
                                        <Form.Label>Datacenter</Form.Label>
                                        <WindowedSelect
                                            name="datacenter"
                                            value={selectedOptions.datacenter ? { label: selectedOptions.datacenter, value: selectedOptions.datacenter } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.datacenter.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="hardwareSku">
                                        <Form.Label>Hardware Sku</Form.Label>
                                        <Form.Control type="text" name="hardwareSku" placeholder="Hardware Sku" onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="region">
                                        <Form.Label>Region</Form.Label>
                                        <WindowedSelect
                                            name="region"
                                            value={selectedOptions.region ? { label: selectedOptions.region, value: selectedOptions.region } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.region.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="deploymentType">
                                        <Form.Label>Deployment Type</Form.Label>
                                        <WindowedSelect
                                            name="deploymentType"
                                            value={selectedOptions.deploymentType ? { label: selectedOptions.deploymentType, value: selectedOptions.deploymentType } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.deploymentType.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="NgsDeviceType">
                                        <Form.Label>Ngs Device Type</Form.Label>
                                        <WindowedSelect
                                            name="ngsDeviceType"
                                            value={selectedOptions.ngsDeviceType ? { label: selectedOptions.ngsDeviceType, value: selectedOptions.ngsDeviceType } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.ngsDeviceType.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId="AllowDcOpsMaintenance">
                                    <Form.Label>Allow DcOps Maintenance <span className="text-danger">*</span></Form.Label>
                                    <Form.Select aria-label="Search by" name="allowDcOpsMaintenance" class="sm" onChange={handleInputChange} required>
                                        <option value=""></option>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="startTime" required >
                                        <Form.Label>Start Time (UTC) <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="datetime-local" name="startTime" placeholder="Maintenance Start Time" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="endTime" required >
                                        <Form.Label required >End Time (UTC) <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="datetime-local" name="endTime" placeholder="Maintenance End Time" onChange={handleInputChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                           <Modal.Footer>
                            <Button variant="primary" type="submit">
                                Submit
                                    </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {submitResponse ? submitResponse.ResponseMessage : 'Error'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {submitResponse ? 'Your form was submitted successfully!' : error}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='primary' onClick={closeModalDone}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="cr-modal"
                        show={showExitModal}>
                        <Modal.Body>
                            <p>You have unsaved changes. Are you sure you want to leave?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={() => {
                                    setShowExitModal(false);
                                    setCloseDisabled(false);
                                    onhide();
                                }}>
                                Leave
                            </Button>
                            <Button variant="primary"
                                onClick={() => {
                                    setShowExitModal(false);
                                }}
                            >Stay</Button>
                        </Modal.Footer>
                    </Modal>
                </Modal.Body>
            </Modal>
        </>
    )
}
