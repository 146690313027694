import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const NotFound = () => {
    return (
        <Container fluid="md">
            <div>
                <h1>Page Not Found</h1>
                <p>Sorry, the page you are looking for cannot be found.</p>
                <Link to="/">Go home</Link>
            </div>
        </Container>
  );
};
export default NotFound;