import React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

const DiffView = ({ oldValue, newValue, splitView }) => {
    const allowedKeys = ['status','maintenanceType','title', 'description', 'result',
                        'unsafeDeviceSmfVersionApprover','validationResult',
                        'scheduledStartTime', 'scheduledEndTime'];

    const oldFiltered = Object.keys(oldValue)
        .reduce((obj, key) => {
            if (allowedKeys.includes(key)) {
                obj[key] = oldValue[key];
            }
            return obj;
        }, {});

    const newFiltered = Object.keys(newValue)
        .reduce((obj, key) => {
            if (allowedKeys.includes(key)) {
                obj[key] = newValue[key];
            }
            return obj;
        }, {});

    const oldString = JSON.stringify(oldFiltered, null, 2);
    const newString = JSON.stringify(newFiltered, null, 2);
    

    return (
        <td>
            <ReactDiffViewer oldValue={oldString} newValue={newString} splitView={splitView} />
        </td>
    );
};

export default DiffView;