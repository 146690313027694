import { Component, useEffect, useState} from 'react';
import { InputButton } from '../common/input-button';
import { FetchData } from '../common/fetch';
import { DeviceGraph } from './device-graph';
import { ConflictDevices } from './conflict-devices';
import { DeviceCount } from './device-count';
import '../../css/App.css';
import { Application } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react"
import { useParams, useNavigate } from 'react-router-dom';


export function ConflictGraph() {
    const [data, setData] = useState({
        "Devices": [],
        "DeviceLinks": [],
        "ConflictDevicesList": [],
        "ConflictDevices": [],
        "ConflictDeviceLinks": []
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [error, setError] = useState(null)
    const [isSimpleView, setSimpleView] = useState(false);
    const [token, setToken] = useState();
    const { instance, accounts, inProgress } = useMsal();
    const [changeRecordID, setChangeRecordID] = useState('');
    const { changeId } = useParams();

    useEffect(() => {
        const sampleRequest = {
            ...Application.clientApi,
            account: accounts[0]
        }
        instance.acquireTokenSilent(sampleRequest).then((response) => {
            setToken(response.accessToken);
        }).catch((e) => {
            console.log(e, "exception");
        })
    }, []);

    useEffect(() => {
        if (changeId && token) {
            fetchChangeIdData(changeId);
            setChangeRecordID(changeId);
        }
    }, [changeId, token]);


    const fetchChangeIdData = (changeId) => {
        setButtonLoading(true);

        const bearer = 'Bearer ' + token;

        const options = {
            method: 'GET',
            headers: {
                'Authorization': bearer,
            }
        };

        var api = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetConflictGraphForChangeId/?changeRecordID=' + changeId

        fetch(api, options)
            .then(res => res.json())
            .then((result) => {
                if (result["responseCode"] === "00") {
                    setData({
                        "Devices": result["deviceGraph"]["Devices"],
                        "DeviceLinks": result["deviceGraph"]["DeviceLinks"],
                        "ConflictDevicesList": result["deviceGraph"]["ConflictDevicesList"].at(0)["conflictDevices"],
                        "ConflictDevices": result["deviceGraph"]["ConflictDevices"],
                        "ConflictDeviceLinks": result["deviceGraph"]["ConflictDeviceLinks"]
                    })
                    setError(null);
                }
                else {
                    setError(result["responseMessage"]);
                }
            },
                (error) => {
                    setError(error.message);
                })
            .catch((e) => console.log(e, "apiError"))
            .finally(() => {
                setButtonLoading(false);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchChangeIdData(event.target.ChangeRecordID.value);
    };

    const handleInputChange = (event) => {
        setChangeRecordID(event.target.value);
    };



    return (
        <div className="App">
            <div className="container">
                <InputButton
                    handleSubmit={handleSubmit}
                    buttonLoading={buttonLoading}
                    value={changeRecordID}
                    handleInputChange={handleInputChange} 
                />
                {(error !== null) &&
                    <div>
                        <div className="ErrorMessage">
                            {error}
                        </div>
                        <div className="AppText">
                            Please Resubmit Change Record ID.
                        </div>
                    </div>
                }
                {(error === null) &&
                    (data.Devices.length !== 0) &&
                    <div>
                        <DeviceGraph
                            data={data}
                            isSimpleView={isSimpleView}
                            setSimpleView={setSimpleView}
                        />
                        <ConflictDevices
                            data={data}
                        />
                        <DeviceCount
                            data={data}
                            isSimpleView={isSimpleView}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default ConflictGraph;
