import React,{ useEffect } from "react";
import { runForceGraph } from "./force-graph-generator";
import styles from "../../css/force-graph.module.css";

export function ForceGraph({ linksData, nodesData, zoomScale, children }) {
  const containerRef = React.useRef(null);

  useEffect(() => {
    let destroyFn;

    if (containerRef.current) {
      const { destroy } = runForceGraph(containerRef.current, linksData, nodesData, zoomScale);
      destroyFn = destroy;
    }

    return destroyFn;
  }, [linksData, nodesData, zoomScale]);

  return <div ref={containerRef} className={styles.container}>{children}</div>;
}
