import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 


export const Home = () => {

    const navigate = useNavigate();

    
    const cardList = [
        { "path": "/conflictGraph", "title": "Conflict graph generator" },
        { "path": "/changeRecord", "title": "Manage Change Records" },
        { "path": "/blocklist", "title": "Manage Networking blocklist" }
    ];

    return (
        <Container>
            <Row>
                {
                    cardList.map((card) => (
                        <Col onClick={() => navigate(card.path)}>
                            <div class="app-tile" style={{ width: '18rem' }}>
                                <p>{card.title}</p>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    );
};