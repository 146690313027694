import React from 'react';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Constants from '../Constants';
import '../../css/change-record.css';
export const Description = ({
    handleInputChange,
    maintenanceType
}) => {
    const [addnlDescText, setAddnlDescText] = useState(Constants.AddnlDescText[maintenanceType] || Constants.AddnlDescText.Default);
    const [description, setDescription] = useState(addnlDescText);

    useEffect(() => {
        setDescription(Constants.AddnlDescText[maintenanceType] || Constants.AddnlDescText.Default);

    }, [maintenanceType]);

    const handleChange = (e) => {
        const value = e.target.value;
        handleInputChange({
            target: { name: "description", value: value },
        });
        setDescription(value);
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Description: <span className="text-danger">*</span></Form.Label>
            <Form.Control
                name="description"
                as="textarea"
                className="cr-textarea"
                onChange={handleChange}
                value={description}
                required
            />
        </Form.Group>
    );
};