const Constants = {
    responseCode: "ResponseCode",
    responseMessage: "ResponseMessage",
    responseData: "data",
    SuccessAPIResponseCode: "CVB000",
    NoDataFoundAPIResponseCode: "CVB022",
    KustoErrorAPIResponseCode: "CVB103",
    Admin: "Admin",
    blockListFields : [
        {
            "name": "Id",
            "key": "id",
            "type":"text",
            "disabled": true
        },
        {
            "name": "Start Time (UTC)",
            "key": "starttime",
            "type": "date",
            "disabled": false
        },
        {
            "name": "End Time (UTC)",
            "key": "endTime",
            "type": "date",
            "disabled": false
        },
        {
            "name": "Device Regex",
            "key": "deviceRegex",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Ngs Device Type",
            "key": "ngsDeviceType",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Hardware Sku",
            "key": "hardwareSku",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Cluster",
            "key": "cluster",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Deployment Type",
            "key": "deploymentType",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Region",
            "key": "region",
            "type": "text",
            "disabled": false
        },
        {
            "name": "Datacenter",
            "key": "datacenter",
            "type": "text",
            "disabled": false
        }
    ],
    changeRecordHomePageFields: [
        {
            "name": "Change Id",
            "key": "changeId",
            "type": "text",
            "width": 120
        },
        {
            "name": "Title",
            "key": "title",
            "type": "text",
            "width": 400
        },
        {
            "name": "Status",
            "key": "status",
            "type": "text",
            "width": 130
        },
        {
            "name": "Risk",
            "key": "riskLevel",
            "type": "text",
            "width": 130
        },
        {
            "name": "NCVS Approved",
            "key": "isNcvsValidated",
            "type": "text",
            "width": 150
        },
        {
            "name": "Start Time (UTC)",
            "key": "scheduledStartTime",
            "type": "datetime",
            "width": 200
        },
        {
            "name": "End Time (UTC)",
            "key": "scheduledEndTime",
            "type": "datetime",
            "width": 200
        },
        {
            "name": "Modified By",
            "key": "lastUpdatedUser",
            "type": "text",
            "width": 150,
            "hide": true
        }
    ],
    RejectedStatus: "Rejected",
    CancelledStatus: "Cancelled",
    ApprovedStatus: "Approved",
    InReviewStatus: "InReview",
    MopReqMaintenanceTypes: [
        "Default",
        "StorageTestingMaintenance",
        "OpenAITesting",
        "LibraTesting",
        "RDMATesting",
        "BMCOTWTesting",
        "SonicTesting",
        "ToRDegradeTesting",
    ],
    NoMopReqMaintenanceTypes: [
        // "FiberDrainMaintenance", - not live yet
        "NoDrainFiberMaintenance",
        "DcsPlannedMaintenance",
        "DcsPowerDownMaintenance",
        "DcsPduReplacementMaintenance",
        "TycoonPlannedMaintenance",
        "OtherPlannedMaintenance",
        "UKMETTesting"
    ],
    FiberMaintenanceTypes: [
        "FiberDrainMaintenance",
        "NoDrainFiberMaintenance"
    ],
    SafeflyRequiredMaintenanceTypes: [
        "FiberDrainMaintenance",
        "UKMETTesting",
        "Default",
        "StorageTestingMaintenance",
        "OpenAITesting",
        "LibraTesting",
        "RDMATesting",
        "BMCOTWTesting",
        "SonicTesting",
        "ToRDegradeTesting",
    ],
    LossOfRedundancyMaintenanceTypes: [
        "DcsPlannedMaintenance",
        "DcsPowerDownMaintenance",
        "DcsPduReplacementMaintenance"
    ],
    MaintenanceTypeDescriptions: 
    {
        "FiberDrainMaintenance": "Use this maintenance type only when traffic drain is required. Only for multispan fiber maintenance or fiber cut event. For work where damaged fiber must be repaired and additional links must be taken down to perform the fix, fiber is being spliced in a live tray where live fiber will be altered, or in older sites where fiber status is not documented or unknown.VSO to be less 12 hours and performed at off peak hours, typically at night. Traffic drain necessary",
        "NoDrainFiberMaintenance": "Use this maintenance type only when 'NO' Traffic drain is needed",
        "DcsPlannedMaintenance": "Loss of redundancy planned power maintenances by DCS. Include single-PSU c0 devices instead of separate power down ticket",
        "DcsPowerDownMaintenance": "Select this Maintenance Type only if both (all) sources of power will be lost to all network devices",
        "DcsPduReplacementMaintenance": "Select this Maintenance Type only if network device PSUs are currently down, if only other devices are affected, then use the loss of redundancy (DcsPlannedMaintenance) template",
        "TycoonPlannedMaintenance": "PhyNet - Tycoon planned maintenance ONLY",
        "OtherPlannedMaintenance": "All other planned maintenances",
    },
    AddnlDescText:
    {
        "FiberDrainMaintenance": "Please include \n1.Brief description of work item (what is the work being done)\n2.What is the impact of not doing the work",
        "NoDrainFiberMaintenance": "Please include \n1.Brief description of work item (what is the work being done)\n2.What is the impact of not doing the work",
        "DcsPowerDownMaintenance": "Please include \n1.Brief description of work item (what is the work being done)\n2.Attestation that server rack owners have given permission for power down (as applicable)\n3.What is the impact of not doing the work",
        "DcsPlannedMaintenamce": "Please include \n1Brief description of work item (what is the work being done)\n-do NOT use this template for power down/loss of power to network devices -- only for loss of redundancy\n2.What is the impact of not doing the work",
        "DcsPduReplacementMaintenance": "Please include \n1.Brief description of work item (what is the work being done)\n-do NOT use this template for power down/loss of power to network devices -- only for loss of redundancy\n2.What is the impact of not doing the work",
        "Default": "Please include \n1.Brief description of work item (what is the work being done)\n2.What is the impact of not doing the work",
    },
    RiskLevels: [
        "Low",
        "Medium",
        "High"
    ],
    UnsafeDeviceSmfVersionApproverTooltipContent: 'Please provide the email Address of the Microsoft EE approver, who confirms that In accordance with ' +
        'TSB-2020-07-007 this work will not cause a planned interruption to both sources of power to Dell S6100 network switches',
    FilterMapping:
    {
        "ChangeId": "changeId",
        "DeviceName": "devices",
        "Status": "status",
        "LastUpdatedUser": "lastUpdatedUser",
        "ChangesForTeam": "teamtype"
    }
};
export default Constants;