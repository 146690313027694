
import '../../css/App.css';
import NavBar from './NavBar';

export const PageLayout = () => {

    return (
        <div>
            <div>
                <NavBar></NavBar>
                {/*<header className="App-header">
                    Change Management Topology Tool
                    <img className="Logo" src={CMLogo} alt="Logo" />
                    
                </header>*/}
            </div>
        </div>
    )
};