import * as d3 from "d3";
import styles from "../../css/force-graph.module.css";

export function runForceGraph( container, linksData, nodesData, zoomScale ) {
  const links = linksData.map((d) => Object.assign({}, d));
  const nodes = nodesData.map((d) => Object.assign({}, d));

  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height * zoomScale / 3;
  const width = containerRect.width * zoomScale / 3;

  const nodeHoverTooltipCopy = (node) => {
    return `<div>     
      <b>Copied ${node.isConflicting === "True" ? 'Conflict Device' : 'Device'} ID:</b>
      <br>
      <b>${node.id}</b>
    </div>`;
  }

  const color = (d) => {
    return d.isConflicting === "False" ? "#1C39BB" : "#ff4d4d";
  }

  // Drag function to move nodes
  const dragNode = (simulation) => {
    const dragstarted = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (d) => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };

    const dragended = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = d.x;
      d.fy = d.y;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  // Add the tooltip element to the graph
  const tooltip = document.querySelector("#graph-tooltip");
  if (!tooltip) {
    const tooltipDiv = document.createElement("div");
    tooltipDiv.classList.add(styles.tooltip);
    tooltipDiv.style.opacity = "0";
    tooltipDiv.id = "graph-tooltip";
    document.body.appendChild(tooltipDiv);
  }

  const div = d3.select("#graph-tooltip");

  const addTooltip = (hoverTooltip, d, x, y) => {
    div
      .transition()
      .duration(200)
      .style("opacity", 0.9);
    div
      .html(hoverTooltip(d))
      .style("left", `${x}px`)
      .style("top", `${y - 28}px`);
  };

  const removeTooltip = () => {
    div
      .transition()
      .duration(200)
      .style("opacity", 0);
  };

  // Location placement
  const xScale = d3.scalePoint()
    .domain([1, 2, 3])
    .range([-width / 2, width / 2]);

  const simulation = d3
    .forceSimulation(nodes)
    .force("link", d3.forceLink(links).id(d => d.id))
    .force("charge", d3.forceManyBody()
      .strength(-2000)
      .theta(0.5)
      .distanceMax(1500))
    .force('x', d3.forceX((d) => xScale(d.location)).strength(1))
    .force("y", d3.forceY());
  
  const panContainer = () => {
    var viewBoxX = 0, viewBoxY = 0;

    const dragged = () => {
      viewBoxX -= d3.event.dx;
      viewBoxY -= d3.event.dy;
      svg.select('g.device-node-area').attr('transform', 'translate(' + (-viewBoxX) + ',' + (-viewBoxY) + ')');
    };

    return d3
      .drag()
      .on("drag", dragged)
  };

  // Container creation
  const svg = d3
    .select(container)
    .insert("svg")
    .attr("id", "DeviceGraph")
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .call(panContainer());

  const deviceNodeArea = svg.append("g")
    .classed("device-node-area", true);

  const link = deviceNodeArea
    .append("g")
    .attr("stroke", "black")
    .attr("stroke-opacity", 0.6)
    .selectAll("line")
    .data(links)
    .join("line")
    .attr("stroke-width", 1);

  const node = deviceNodeArea
    .append("g")
    .attr("stroke", "#fff")
    .attr("stroke-width", 2)
    .selectAll("circle")
    .data(nodes)
    .join("circle")
    .attr("r", 15)
    .attr("fill", d => {return color(d);})
    .call(dragNode(simulation));
  
  node.on("click", (d) => {
    navigator.clipboard.writeText(d.id);
    addTooltip(nodeHoverTooltipCopy, d, d3.event.pageX, d3.event.pageY);
  })
    .on("mouseout", () => {
      removeTooltip();
    });

  const label = deviceNodeArea
    .append("g")
    .attr("class", "labels")
    .selectAll("text")
    .data(nodes)
    .enter()
    .append("text")
    .attr('text-anchor', 'middle')
    .attr("dy", 20)
    .attr('dominant-baseline', 'central')
    .attr('font-weight', '500')
    .text(d => d.id);

  label.on("click", (d) => {
    navigator.clipboard.writeText(d.id);
    addTooltip(nodeHoverTooltipCopy, d, d3.event.pageX, d3.event.pageY);
  })
    .on("mouseout", () => {
      removeTooltip();
    });

  simulation.on("tick", () => {
    // update link positions
    link
      .attr("x1", d => d.source.x)
      .attr("y1", d => d.source.y)
      .attr("x2", d => d.target.x)
      .attr("y2", d => d.target.y);

    // update node positions
    node
      .attr("cx", d => d.x)
      .attr("cy", d => d.y)

    // update label positions
    label
      .attr("x", d => { return d.x; })
      .attr("y", d => { return d.y; })
  })

  return {
    destroy: () => {
      const deviceGraph = document.getElementById("DeviceGraph");
      simulation.stop();
      if (deviceGraph) {
        deviceGraph.remove();
      }
    },
    nodes: () => {
      return svg.node();
    }
  };
}
