import { Button, Form } from 'react-bootstrap';
import '../../css/App.css'

export const InputButton = ({ handleSubmit, buttonLoading, value, handleInputChange }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="ChangeRecordID">
                <div className="Input-Bar-Item">
                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            name="ChangeRecordID"
                            placeholder="Change Record ID"
                            value={value}
                            onChange={handleInputChange}
                        />
                        <span className="input-group-btn input-space">
                            <Button
                                className="InputButton"
                                type="submit"
                                disabled={buttonLoading}
                            >
                                {buttonLoading ? "Loading..." : "Submit"}
                            </Button>
                        </span>
                    </div>
                </div>
            </Form.Group>
        </Form>
    )
}
