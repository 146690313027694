import { Application } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { Button } from 'react-bootstrap';

function signOutClickHandler(instance) {
    instance.logoutRedirect(Application.login);
}

function signInClickHandler(instance) {
    instance.loginRedirect(Application.login).catch(e => {
        console.log(e);
    });
}

function SignInButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return (
        <Button name= "signInButton" onClick={() => signInClickHandler(instance)}>Sign In</Button>
    );
}

// SignOutButton Component returns a button that invokes a redirect logout when clicked
function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return (
        <Button onClick={() => signOutClickHandler(instance)}>Sign Out</Button>
    );
}

export function PerformAuthAction({ actionType }) {
    return actionType == "login" ? SignInButton() : SignOutButton();
}