import Select from 'react-select'
import { Form, Row, Col } from 'react-bootstrap';
import Constants from '../Constants';

export const RiskOrSafefly = ({ isMopRequired, handleInputChange, maintenanceType }) => {
    const handleRiskSelection = (selectedOption) => {
        handleInputChange({
            target: { name: "riskLevel", value: selectedOption.value },
        });
    };

    if (!Constants.SafeflyRequiredMaintenanceTypes.includes(maintenanceType)) {
        if (maintenanceType === "NoDrainFiberMaintenance") {
            return;
        }
        else
        {
            let riskLevels = Constants.RiskLevels;
            const options = riskLevels.map((riskLevel) => ({ value: riskLevel, label: riskLevel, name: 'riskLevel' }));
            return (
                <Form.Group className="mb-3">
                    <Form.Label> Risk<span className="text-danger">*</span></Form.Label>
                    <Select name="riskLevel" options={options} onChange={handleRiskSelection}>
                    </Select>
                </Form.Group>
            );
        }
    }
    else {
        return (
            <Form.Group className="mb-3">
                <Form.Label>Safefly ID<span className="text-danger">*</span></Form.Label>
                <Form.Control required name="safeflyId" type='number' className='no-arrow'
                    onChange={(event) => handleInputChange(event)} />
            </Form.Group>
        );
    }
}