import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import '../../css/change-record.css';

export const DateRangePicker = ({ startWindow: initialStartWindow, endWindow: initialEndWindow, onDateRangeChange, refreshTable, setRefreshTable }) => {
    const [startWindow, setStartWindow] = useState(initialStartWindow || '');
    const [endWindow, setEndWindow] = useState(initialEndWindow || '');
    const [error, setError] = useState('');
    const [autoRefresh, setAutoRefresh] = useState(false);

    const handleStartDateChange = (event) => {
        setStartWindow(event.target.value);
        setError('');
    };

    const handleEndDateChange = (event) => {
        setEndWindow(event.target.value);
        setError('');
    };

    const handleSearch = () => {
        if (startWindow > endWindow) {
            setError('Start date must be before end date');
        } else {
            setError('');
            // Call the callback to submit the date range
            if (onDateRangeChange) {
                onDateRangeChange({ startWindow, endWindow });
            }
        }
    };

    useEffect(() => {
        if (autoRefresh) {
            const interval = setInterval(() => {
                setRefreshTable(!refreshTable);
            }, 300000); // Refresh every 5 minutes

            return () => clearInterval(interval);
        }
    }, [autoRefresh, refreshTable, setRefreshTable]);

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col className="datepicker-title" sm={3}>
                        Changes Scheduled between:
                    </Col>
                    <Col sm={2} className='initial-start-window'>
                        <Form.Control type="date" defaultValue={initialStartWindow} onChange={handleStartDateChange} />
                    </Col>
                    <Col sm={2} className='initial-end-window'>
                        <Form.Control type="date" defaultValue={initialEndWindow} onChange={handleEndDateChange} />
                    </Col>
                    <Col sm={1} className='go-btn-col'>
                        <Button
                            onClick={handleSearch}
                            aria-label="Go button">&nbsp; Go &nbsp;
                        </Button>
                        {error && <p>{error}</p>}
                    </Col>
                    <Col>
                    <Box display="flex" justifyContent="flex-end">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={autoRefresh}
                                        onChange={(event) => setAutoRefresh(event.target.checked)}
                                        inputProps={{ 'aria-label': 'Auto Refresh' }}
                                    />
                                }
                                label="Auto Refresh"
                            />

                        </Box>
                        </Col>

                </Row>
            </Form.Group>
        </Form>
    );
}
