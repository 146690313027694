import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import '../../css/NavBar.css'
import { PerformAuthAction } from "./SignInOutButton";
import { AuthenticatedTemplate } from "@azure/msal-react";

const NavBar = () => {

    return (
        <>
            <Navbar bg="primary" variant="dark">
                <Container fluid>
                    <Navbar.Brand href="/">Change Management Tool</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <AuthenticatedTemplate>
                    <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Nav.Link href="#/conflictGraph">Conflict Graph Generator </Nav.Link>
                                <Nav.Link href="#/blocklist">Manage Networking Blocklist</Nav.Link>
                                <Nav.Link href="#/changeRecord">Manage Change Records</Nav.Link>
                            </Nav>
                    </Navbar.Collapse>
                        <PerformAuthAction actionType="logout" />
                    </AuthenticatedTemplate>
                </Container>
            </Navbar>
            <br />
        </>
    );
};

export default NavBar;