import { Button, Form, InputGroup } from 'react-bootstrap';

export const SearchForm = ({
    filterType, buttonLoading, handleFilterInputChange, setSearchFilterText,
    handleSearchSubmit, setFilterFromView, filterFromView, handleClearFilter }) => {
        return (
        <Form
            onSubmit={handleSearchSubmit}
            id='searchForm'
            name='searchForm'
            aria-label='searchForm' >
            <Form.Group className="input-group">
                <div className="input-group">
                    <InputGroup className="mb-2 align-items-center">
                        <Form.Select required name="searchBy" aria-label="Search by" className="sm search-by" onChange={handleFilterInputChange}>
                            <option value="">Search By</option>
                            <option value="ChangeId">Change Id</option>
                            <option value="DeviceName">Device Name</option>
                            <option value="Status">Status</option>
                            <option value="LastUpdatedUser">Modified by </option>
                            <option value="teamtype">Team</option>
                        </Form.Select>
                        {filterType === "Status" ? (
                            <Form.Select required name="searchText" aria-label="Search" onChange={event => setSearchFilterText(event.target.value)}>
                                <option value="">Select Status</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Approved">Approved</option>
                                <option value="InReview">InReview</option>
                            </Form.Select>
                        ) : filterType === "LastUpdatedUser" ? (
                            <Form.Control required name="searchText" type="text" placeholder="alias, name" aria-label="Search" onChange={event => setSearchFilterText(event.target.value)} />
                        ) : filterType === "teamtype" ? (
                            <Form.Select required name="searchText" aria-label="Search" onChange={event => setSearchFilterText(event.target.value)}>
                                <option value="">Select Team</option>
                                <option value="phynet">Phynet</option>
                                <option value="tycoon">Tycoon</option>
                            </Form.Select>
                        ) : (
                            <Form.Control required name="searchText" type="text" aria-label="Search" onChange={event => setSearchFilterText(event.target.value)} onInput={event => event.target.setCustomValidity('')} />
                        )}
                        <span className="input-group-btn input-space">
                            <Button className="InputButton" name="searchButton" type="submit" disabled={buttonLoading}>
                                {buttonLoading ? "Loading..." : "Search"}
                            </Button>
                        </span>
                        <Form.Check
                            className='filter-from-view'
                            type="checkbox"
                            label="filter from view"
                            checked={filterFromView} 
                            onChange={event => {
                                if (event.target.checked) {
                                    setFilterFromView(true);
                                } else {
                                    handleClearFilter();
                                }
                            }} 
                        />
                        {filterFromView && (
                            <Button name="clearFilterButton" variant="link"
                                onClick={handleClearFilter}>
                                Clear Filter
                            </Button>
                        )}
                    </InputGroup>
                </div>
            </Form.Group>
        </Form>
    );
}
