import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const TooltipOnHover = ({ placement, content }) => {
    return (
        <>
                <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                        <Tooltip id={`tooltip-${placement}`}>
                            {content}
                        </Tooltip>
                    }
                >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" width={15} height={15}>
                    <path d="M0 960q0-132 34-254t97-230 150-194 195-150 229-97T960 0q132 0 254 34t229 97 195 151 150 194 97 229 35 255q0 132-34 254t-97 230-150 194-195 150-229 97-255 35q-133 0-255-34t-229-97-194-150-150-194-97-230T0 960zm1056-384q0-40-28-68t-68-28q-40 0-68 28t-28 68q0 40 28 68t68 28q40 0 68-28t28-68zm-32 768V832q0-26-19-45t-45-19q-26 0-45 19t-19 45v512q0 26 19 45t45 19q26 0 45-19t19-45z" />
                </svg>
                </OverlayTrigger>
        </>
    );
}