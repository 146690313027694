import React from 'react';
import { Modal, Button } from 'react-bootstrap';
export const ExitModal = ({ showExitModal, setShowExitModal, setCloseDisabled, onhide }) => {
    return (
        <Modal className="cr-modal" show={showExitModal}>
            <Modal.Body>
                <p>You have unsaved changes. Are you sure you want to leave?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary"
                    onClick={() => {
                        setShowExitModal(false);
                        setCloseDisabled(false);
                        onhide();
                    }}>
                    Leave
                </Button>
                <Button variant="primary"
                    onClick={() => {
                        setShowExitModal(false);
                    }}
                >Stay</Button>
            </Modal.Footer>
        </Modal>
    );
};