import { Button, Form, Spinner, Modal, Row, Col } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';
import { debounce } from 'lodash';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Application } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { useAdmin} from '../../contexts/AdminContext';

export const ViewBlockListItem = ({ show, item, onhide }) => {
    const [data, setData] = useState(item);
    const { instance, accounts } = useMsal();
    const { isAdmin } = useAdmin();
    const [token, setToken] = useState();
    const [error, setError] = useState(null)
    const [dropdownData, setDropdownData] = useState(null);
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [matchedData, setMatchedData] = useState({ 'cluster': [] });
    const [submitResponse, setSubmitResponse] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [closeDisabled, setCloseDisabled] = useState(false);


    /* gets token for api calls */
    useEffect(() => {
        const request = {
            ...Application.clientApi,
            account: accounts[0]
        };

        instance
            .acquireTokenSilent(request)
            .then((response) => {
                setToken(response.accessToken);
            })
            .catch((e) => {
                console.log(e, "exception");
            });
    }, [instance, accounts]);

    /* Gets data for dropdown*/
    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                try {
                    const bearer = "Bearer " + token;
                    const options = {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': bearer,
                        },
                    };
                    const url = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/DropdownData';
                    const response = await fetch(url, options);
                    const data = await response.json();
                    setDropdownData(data.data);
                    setLoading(false);
                } catch (error) {
                    console.log(error, "exception");
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [token]);

    const handleInputChange = (e) => {
        setCloseDisabled(true);
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    /*Handles searching for dropdown */
    const handleSelectInputChange = debounce((inputValue, action) => {
        setSearching(true);
        const { name } = action;
        if (inputValue && inputValue.length >= 2) {
            const filteredList = dropdownData[name]?.filter(data =>
                data.toLowerCase().includes(inputValue.toLowerCase())
            ).sort();
            setMatchedData(prev => ({ ...prev, [name]: filteredList }));
        } else {
            setMatchedData(prev => ({ ...prev, [name]: [] }));
        }
        setSearching(false);
    }, 300);

    /* handles input change for dropdown */
    const handleSelectChange = (selectedOption, action) => {
        setCloseDisabled(true);
        const { name } = action;
        setSelectedOptions(prev => ({ ...prev, [name]: selectedOption ? selectedOption.value : "" }));
        setData(prev => ({ ...prev, [name]: selectedOption ? selectedOption.value : "" }));
    };

    const convertToDateTimeString = (dateTimeValue) => {
        const date = new Date(dateTimeValue);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleUpdate = (event) => {

        const bearer = "Bearer " + token;

        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearer,
            },
            body: JSON.stringify(data)
        };

        event.preventDefault();
        const url = process.env.REACT_APP_API_CLIENT_URL + 'BlockList/UpdateBlocklist'
        if (!closeDisabled) {
            return;
        }
        const startTime = new Date(data.startTime);
        const endTime = new Date(data.endTime);

        // Check if the end time is before the start time
        if (endTime < startTime) {
            setError("End time must be after start time");
            setShowModal(true);
            return;
        }
        fetch(url, options)
            .then(res => res.json())
            .then((result) => {
                setSubmitResponse(result);
                setCloseDisabled(false);
                setShowModal(true);
            },
                (error) => {
                    setError(error);
                    setShowModal(true);

                })
            .catch((e) => {
                console.log(e, "apiError");
                setShowModal(true);
            }
            );

    };

    const closeModalDone = () => {
        onhide();
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const handleClose = () => {
        if (closeDisabled) {
            setShowExitModal(true);
        }
        else {
            onhide();
        }
    };


    return (
        <>
            <Modal className="cr-modal" size="lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Blocklist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                            <Spinner animation="border" role="status" variant="primary">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Form onSubmit={handleUpdate} className="col-12">
                            <Form.Group className="mb-3" controlId="deviceRegex">
                                <Form.Label>Device List regex</Form.Label>
                                <Form.Control type="text" name="deviceRegex" placeholder="Regular expression" defaultValue={data.deviceRegex} onChange={handleInputChange} />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="clusterName">
                                        <Form.Label>Cluster Name</Form.Label>
                                        <WindowedSelect
                                            placeholder="Search..."
                                            name="cluster"
                                            value={data.cluster ? { label: data.cluster, value: data.cluster } : null}
                                            onChange={handleSelectChange}
                                            onInputChange={(inputValue) => handleSelectInputChange(inputValue, { name: "cluster" })}
                                            options={matchedData?.cluster?.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={({ inputValue }) => inputValue?.length === 0 ? "" : "No Results Found"} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="datacenter">
                                        <Form.Label>Datacenter</Form.Label>
                                        <WindowedSelect
                                            name="datacenter"
                                            value={data.datacenter ? { label: data.datacenter, value: data.datacenter } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.datacenter.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="hardwareSku">
                                        <Form.Label>Hardware Sku</Form.Label>
                                        <Form.Control type="text" name="hardwareSku" placeholder="Hardware Sku" defaultValue={data.hardwareSku} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="region">
                                        <Form.Label>Region</Form.Label>
                                        <WindowedSelect
                                            name="region"
                                            value={data.region ? { label: data.region, value: data.region } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.region.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="deploymentType">
                                        <Form.Label>Deployment Type</Form.Label>
                                        <WindowedSelect
                                            name="deploymentType"
                                            value={data.deploymentType ? { label: data.deploymentType, value: data.deploymentType } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.deploymentType.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="NgsDeviceType">
                                        <Form.Label>Ngs Device Type</Form.Label>
                                        <WindowedSelect
                                            name="ngsDeviceType"
                                            value={data.ngsDeviceType ? { label: data.ngsDeviceType, value: data.ngsDeviceType } : null}
                                            onChange={handleSelectChange}
                                            options={dropdownData.ngsDeviceType.map(item => ({ label: item, value: item })) || []}
                                            isSearchable={true}
                                            isLoading={searching}
                                            noOptionsMessage={() => "No Results Found"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row><Form.Group className="mb-3" controlId="AllowDcOpsMaintenance">
                                <Form.Label>Allow DcOps Maintenance <span className="text-danger">*</span></Form.Label>
                                <Form.Select aria-label="Search by" name="allowDcOpsMaintenance" class="sm" defaultValue={data.allowDcOpsMaintenance} onChange={handleInputChange} required>
                                    <option value=""></option>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </Form.Select>
                            </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="startTime">
                                        <Form.Label>Start Time <span className="text-danger">*</span></Form.Label>
                                        <Form.Control required type="datetime-local" name="startTime" placeholder="Maintenance Start Time" defaultValue={data && data.startTime ? convertToDateTimeString(data.startTime) : ''} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="endTime">
                                        <Form.Label>End Time <span className="text-danger">*</span></Form.Label>
                                        <Form.Control required type="datetime-local" name="endTime" placeholder="Maintenance End Time" defaultValue={data && data.endTime ? convertToDateTimeString(data.endTime) : ''} onChange={handleInputChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {isAdmin && (
                                <Modal.Footer>
                                        <Button disabled={!closeDisabled} variant="primary" type="submit">
                                        Update
                                    </Button>
                                </Modal.Footer>
                            )}
                        </Form>
                    )}
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {submitResponse ? submitResponse.ResponseMessage : 'Error'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {submitResponse ? 'Your request was submitted successfully!' : error}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='primary' onClick={closeModalDone}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal className="cr-modal"
                        show={showExitModal}>
                        <Modal.Body>
                            <p>You have unsaved changes. Are you sure you want to leave?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={() => {
                                    setShowExitModal(false);
                                    setCloseDisabled(false);
                                    onhide();
                                }}>
                                Leave
                            </Button>
                            <Button variant="primary"
                                onClick={() => {
                                    setShowExitModal(false);
                                }}
                            >Stay</Button>
                        </Modal.Footer>
                    </Modal>
                </Modal.Body>
            </Modal>
        </>
    )
}
