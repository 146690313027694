export const extractName = (lastUpdatedUser) => {
    var name = lastUpdatedUser.substring(0, lastUpdatedUser.indexOf('<')).trim();
    return name;
}

export const extractPrettyTime = (lastUpdatedTimestamp, raw = false) => {
    var date = new Date(lastUpdatedTimestamp);
    if (raw) {
        return date.toLocaleString();
    }
    return date.toLocaleString() + ' (UTC)';
}