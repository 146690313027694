import WindowedSelect from "react-windowed-select";
import { Form } from "react-bootstrap";

export const MopDropdown = ({ mop, setCloseDisabled, selectedMop, setSelectedMop, isMopRequired, maintenanceType }) => {

    const handleMopSelection = (selectedOption) => {
        setCloseDisabled(true);
        setSelectedMop({
            ...selectedMop,
            mopName: selectedOption.value.mopName,
            mopId: selectedOption.value.mopId,
            devices: selectedOption.value.devices,
            requestor: selectedOption.value.requestor,
            isNcvsApproved: selectedOption.value.isNcvsApproved,
            ncvsDashboardUrl: selectedOption.value.ncvsDashboardUrl
        });
    }

    if (!isMopRequired) return null;
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Mops: <span className="text-danger">*</span></Form.Label>
                <WindowedSelect
                    key={maintenanceType}
                    name="mopName"
                    onChange={handleMopSelection}
                    options={
                        mop?.map((item) => ({ label: item.mopName, value: item })) || []
                    }
                    isSearchable={true}
                    noOptionsMessage={() => "No Mops Found"}

                />
            </Form.Group>
            {selectedMop?.mopId &&
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Is MOP validated in NCVS</Form.Label>
                        <Form.Control name="isNcvsApproved" readOnly type="text" value={selectedMop?.isNcvsApproved} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <a href={selectedMop?.ncvsDashboardUrl} target="_blank" rel="noopener noreferrer">NCVS Validation Result</a>
                    </Form.Group></>
            }
        </>
    );
};
