import { Route, Routes, HashRouter } from "react-router-dom";
import ConflictGraph from "../conflict-devices/ConflictGraph";
import { Home } from "../Home";
import { ChangeRecordHomePage } from "../change-record/ChangeRecordHomePage";
import { BlockListHomePage } from "../blocklist-management/BlockListHomePage";
import { ChangeRecordDiff } from "../change-record/ChangeRecordDiff";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./NotFound";

export const Switches = () => {
    return (
        <div>
            <HelmetProvider>
                <HashRouter>
                    <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route path="conflictGraph/:changeId?" element={<ConflictGraph />} > </Route>
                        <Route exact path="blocklist" element={<BlockListHomePage />} />
                        <Route exact path="changeRecord/:changeId?" element={<ChangeRecordHomePage />} />
                        <Route exact path="changeHistory/:changeId?" element={<ChangeRecordDiff />} />
                        <Route path="*" element={<NotFound />}></Route>
                    </Routes>
                </HashRouter>

            </HelmetProvider>

        </div>
    );
}