import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row} from 'react-bootstrap';
import Constants from '../Constants';
import '../../css/change-record.css';
import { ExitModal } from '../common/ExitModal';
import { TooltipOnHover } from '../common/Tooltip';
import { MopDropdown } from './mop-dropdown';
import { MaintenanceTypeDropdown } from './maint-type-dropdown';
import { DevicePresentInMop } from './devices-in-maint';
import { RiskOrSafefly } from './risk-or-safefly';
import { Description } from './add-change-description';

export const AddChangeRecord = ({ show, bearer, user, onhide, setEditAndViewModal }) => {
    // True when an edit has been made
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    // TODO: Use to provide clear error messages
    const [submitResponse, setSubmitResponse] = useState(null);
    const [error, setError] = useState(false);
    const [mop, setMop] = useState(null);
    const [mopError, setMopError] = useState(null);
    const [selectedMop, setSelectedMop] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [maintenanceType, setMaintenanceType] = useState('');
    const [isMopRequired, setIsMopRequired] = useState("");

    useEffect(() => {
        async function fetchData() {
            try {
                const options = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": bearer,
                    }
                };
                var url = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/GetMopsApprovedInNcvs?cutoOffTimeInDays=7';
                const response = await fetch(url, options);
                const resultJson = await response.json();
                if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                    throw new Error(resultJson[Constants.responseMessage])
                }
                await setMop(resultJson.data);

            }
            catch (error) {
                await setMopError(error);
            }
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        setSelectedMop(prevMop => {
            const newMop = { ...prevMop };
            delete newMop.mopName;
            delete newMop.mopId;
            delete newMop.devices;
            delete newMop.requestor;
            delete newMop.riskLevel;
            delete newMop.safeflyId;
            delete newMop.isNcvsApproved;
            delete newMop.ncvsDashboardUrl;
            delete newMop.unsafeDeviceSmfVersionApprover;
            delete newMop.description
            return newMop;
        });
    }, [isMopRequired, maintenanceType]);


    const handleClose = () => {
        if (closeDisabled) {
            setShowExitModal(true);
        }
        else {
            onhide();
        }
    };

    const handleSubmitClose = () => {
        setShowSubmitModal(false);

    };

    /*1. Turn on change flag and record changes being made */
    const handleInputChange = (event) => {
        setCloseDisabled(true);
        const { name, value } = event.target;
        setSelectedMop((prevData) => {
            const newData = { ...prevData };
            if (!newData.hasOwnProperty(name)) {
                newData[name] = value;
            } else {
                newData[name] = value;
            }
            return newData;
        });
    };

    async function handleSubmit(event) {
        try {
            event.preventDefault();
            // No changes made, keep modal open
            if (!closeDisabled) {
                return;
            }
            setButtonLoading(true);

            if (selectedMop.scheduledStartTime > selectedMop.scheduledEndTime) {
                setError("End time must be greater than start time");
                setShowSubmitModal(true);
                return;
            }
            if (!selectedMop.description) {
                setError("Description is required");
                setShowSubmitModal(true);
                return;
            }
            selectedMop.lastUpdatedUser = user;
            const options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer,
                },
                body: JSON.stringify(selectedMop)
            };
            var url = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/AddChange'
            const response = await fetch(url, options);
            const resultJson = await response.json();

            if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                if (resultJson[Constants.responseCode].startsWith("CVB1")) {
                    throw new Error("There was an error validating your change. Please try again.");
                } else {
                    throw new Error(resultJson[Constants.responseMessage]);   
                }
            }
            // Kusto Error
            if (resultJson.data.validationResult?.rejectedChecks.length === 1 &&
                    resultJson.data.validationResult?.rejectedChecks[0][Constants.responseCode] === Constants.KustoErrorAPIResponseCode) {
                    throw new Error();
            }

            await setEditAndViewModal(resultJson.data, true)
            setButtonLoading(false);
            onhide();

        }
        catch (error) {
            await setError(error.message || "There was an error validating your change");
            setShowSubmitModal(true);
        }
        finally {
            setButtonLoading(false);

        }
    }

    return (
        <>
            <Modal className="cr-modal" size="lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule new maintenance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <MaintenanceTypeDropdown
                            handleInputChange={handleInputChange}
                            maintenanceType={maintenanceType}
                            setMaintenanceType={setMaintenanceType}
                            isMopRequired={isMopRequired}
                            setIsMopRequired={setIsMopRequired}
                            />
                        {maintenanceType && (
                            <>
                                <RiskOrSafefly isMopRequired={isMopRequired} handleInputChange={handleInputChange} maintenanceType={maintenanceType} />
                                <MopDropdown mop={mop}
                                    setCloseDisabled={setCloseDisabled}
                                    selectedMop={selectedMop}
                                    setSelectedMop={setSelectedMop}
                                    isMopRequired={isMopRequired}
                                    maintenanceType={maintenanceType} />
                                <Form.Group className="mb-3">
                                    <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                                    <Form.Control required name="title" onChange={handleInputChange} defaultValue={selectedMop ? selectedMop.title : ''} />
                                </Form.Group>
                                <Description
                                    handleInputChange={handleInputChange}
                                    maintenanceType={maintenanceType}
                                />
                                {Constants.LossOfRedundancyMaintenanceTypes.includes(maintenanceType) && (                                
                                <Form.Group className="mb-3">
                                    <Form.Label>TSB-2020-07-007 Microsoft EE Approver <TooltipOnHover placement={'right'} content={Constants.UnsafeDeviceSmfVersionApproverTooltipContent} /></Form.Label>
                                    <Form.Control name="unsafeDeviceSmfVersionApprover" onChange={handleInputChange} defaultValue={selectedMop ? selectedMop.unsafeDeviceSmfVersionApprover : ''} />
                                </Form.Group>
                            )}
                        <Row>
                            <Form.Group className="mb-3 col-6" controlId="startTime">
                                <Form.Label>Start Time (UTC) <span className="text-danger">*</span></Form.Label>
                                <Form.Control required type="datetime-local" name="scheduledStartTime" onChange={handleInputChange} defaultValue={selectedMop ? selectedMop.scheduledStartTime : ''} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="endTime">
                                <Form.Label>End Time (UTC) <span className="text-danger">*</span></Form.Label>
                                <Form.Control required type="datetime-local" name="scheduledEndTime" onChange={handleInputChange} defaultValue={selectedMop ? selectedMop.scheduledEndTime : ''} />
                            </Form.Group>
                        </Row>
                        <DevicePresentInMop isMopRequired={isMopRequired} maintenanceType={maintenanceType} selectedMop={selectedMop} setSelectedMop={setSelectedMop} />
                        <Form.Group className="mb-3 mt-4 pt-2">
                            <Button
                                className="InputButton"
                                type="submit"
                                disabled={buttonLoading}>
                                        {buttonLoading ? "Validating Change..." : "Submit"}
                            </Button>
                        </Form.Group>
                            </>)
                        }
                    </Form>
                </Modal.Body>
            </Modal>
            <ExitModal
                showExitModal={showExitModal}
                setShowExitModal={setShowExitModal}
                setCloseDisabled={setCloseDisabled}
                onhide={onhide}
            />
            <Modal show={showSubmitModal} onHide={handleSubmitClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Error
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error ? (
                        <div>{error}</div>
                    ) : (
                        <div>There was an error validating your change</div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSubmitClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
